/*
 * Configuration
 */

export const ENGAGEMENT_LINK = 'https://sasakistrategies.typeform.com/DTLRMPSurvey3';

export const ENGAGEMENT_LINK_ES = 'https://sasakistrategies.typeform.com/to/SqFor9uk';

export const DRAFT_PLAN_LINK = 'https://firebasestorage.googleapis.com/v0/b/downtown-littlerock-masterplan.appspot.com/o/Downtown%20Little%20Rock%20Master%20Plan%20Final%20Draft_reduced.pdf?alt=media&token=5f1aa3fe-680c-442e-9465-d03fc2470c56';

export const FINAL_DRADT_PLAN_LINK = 'https://firebasestorage.googleapis.com/v0/b/downtown-littlerock-masterplan.appspot.com/o/DTLR%20Master%20Plan_FINAL_June2024.pdf?alt=media&token=8898be8f-6e4d-490d-a03b-3911b8f8694c'

export const PROVIDE_COMMENTS = 'https://sasakistrategies.typeform.com/to/LJEtuzLT';

export const HEAP_ID = '';

export const FORMSPREE_URL = 'https://formspree.io/f/mvojvvbr';

export const MOBILE_BREAKPOINT = 1200;

export const MENU = [
    { text: '', href: '#welcome' },
    { text: 'Vision', href: '#vision' },
    { text: 'Team', href: '#team' },
   

    { text: 'Share Your Ideas', href: '#share' },
    { text: 'Resources', href: '#resources' },
    { text: 'Process', href: '#process' },
];
