import { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { List, Accordion, Title, Header, Body } from '@strategies/ui';
import { useHenshuApp } from '@strategies/henshu-app';
import NavSection from '../NavSection';


export default observer(function Process() {
    const [height, setHeight] = useState('420px');

    const onImageRef = useCallback(ref => {
        if (ref) {
            const setDims = () => {
                const { width } = ref.getBoundingClientRect();
                setHeight(`${width / 1.47}px`);
            };

            (new ResizeObserver(setDims)).observe(ref);

            setDims();
        }
    }, []);
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;
    return (
        <NavSection index={5} name="Process">
              <henshu.richtext {...bindTo('engage.Process.body')} />

              <div className="image-wrap">
                         <henshu.img
                             get={() => bindTo('engage.Process.image').get()}
                             set={async (t) => bindTo('engage.Process.image').set(t ? await upload(t) : t)}
                         />
                     </div>
        </NavSection>
    );
});
