/*
 * Seeds
 *
 * Use this file to populate an empty Firestore or reset the content
 * of the Firestore.
 */



const seeds = {
    en: {
        'engage.banner': 'Share your ideas and experiences to build the future of the University of New Mexico',
        'engage.title': 'Downtown Little Rock Master Plan',
        'engage.animation.icon1': 'Eat',
        'engage.animation.icon2': 'Gather',
        'engage.animation.icon3': 'Walk',
        'engage.animation.icon4': 'Transit',
        'engage.animation.icon5': 'Live',
        'engage.animation.prompt1': 'Where do you ...',
        'engage.animation.prompt2': 'How do you get around ...',

        'engage.engagement.title': 'Engagement Survey',
        'engage.engagement.button': 'Share Your Ideas',

        'engage.Welcome.title': "Let's envision the future of UNM campus together.",
        'engage.Welcome.body': "Share your ideas and experiences to build the future of the University of New Mexico",

        'engage.Vision.title': "About the Master Plan",
        'engage.Vision.subtitle': "Vision",
        'engage.Vision.body': "<p>The University of New Mexico has commenced its Integrated Campus Plan process and retained Sasaki Associates to lead a multidisciplinary team to assist with the development of the plan. The Integrated Campus Plan process is grounded in the University’s Strategic Plan <a href='' rel='noopener noreferrer' target='_blank'>UNM 2040: Opportunity Defined</a> and other previous planning efforts and will assist the University in physically translating its academic mission and strategic vision. The Integrated Campus Plan will focus on the facilities, grounds, and physical assets of the institution to provide a “road map” to fulfill the vision.</p>",
        'engage.Vision.historical.title': 'In the foreground sits Hodgin Hall, the first building built at UNM',
        'engage.Vision.historical.image': '',
        'engage.Vision.present.title': 'Recently updated Smith Plaza in front of Zimmerman Library',
        'engage.Vision.present.image': '',

        'engage.Process.title': "The planning process",
        'engage.Process.subtitle': "Process",
        'engage.Process.body': 'This is a new paragraph section.',

        'engage.Process.items': [
            {
                'duration': "September '22 - November '23",
                'title': 'On-Going Engagement',
                'body': 'You are at the heart of this story. At every point in the project, we will reach out to you and others across UNM’s many communities to ask for your insight, reactions, and feedback both virtually and in-person. This website will always have the latest news on how you can share your voice! Sign up to get updates.[link to sign up form at the bottom of the page]',
            },
            {
                'name': 'Phase 01',
                'duration': "September 2022 - December 2022",
                'title': 'Discovery and Analysis',
                'body': 'During the first phase, the Sasaki team will gather and integrate past campus plans and studies and conduct a thorough site visit to understand current conditions and key objectives for the Integrated Campus Plan. Key phase one developments will include a series of meetings with the core UNM planning team (core team) and facilities management, collaborative defining of guiding principles, and stakeholder interviews with members of the UNM community from executive leadership to regents, administrators, faculty, staff, students, and community partners.',
            },
            {
                'name': 'Phase 02',
                'duration': 'January 2023 - July 2023',
                'title': 'Development and Drafting of the Plan',
                'body': 'During the first phase, the Sasaki team will gather and integrate past campus plans and studies and conduct a thorough site visit to understand current conditions and key objectives for the Integrated Campus Plan. Key phase one developments will include a series of meetings with the core UNM planning team (core team) and facilities management, collaborative defining of guiding principles, and stakeholder interviews with members of the UNM community from executive leadership to regents, administrators, faculty, staff, students, and community partners.',
            },
            {
                'name': 'Phase 03',
                'duration': 'August 2023 - November 2023',
                'title': 'Implementation',
                'body': 'Based on the input our team gathered throughout Phase 2, we will develop a preferred plan. This will illustrate key ideas and recommendations of the selected plan and its supporting strategies in greater detail. The planning team will also identify priority projects to help the University develop an approach to phasing and implementation of future projects. The draft ICP plan will be reviewed by UNM’s core team and any public review the University deems necessary.',
            },
        ],

        'engage.Resources.title': "Project resources",
        'engage.Resources.subtitle': "Resources & Updates",
        'engage.Resources.body': "",

        'engage.resources': [
            {
                'title': 'UNM 2040',
                'subtitle': 'Link to Website',
                'action': 'link',
                'image': '',
            }
        ],

        'engage.Contact.title': "Share your ideas",
        'engage.Contact.subtitle': "Contact",
        'engage.Contact.prompt.name': 'Hi, my name is',
        'engage.Contact.prompt.role': "and I'm a",
        'engage.Contact.prompt.email': "and my email address is",
        'engage.Contact.prompt.message': "my message",
        'engage.Contact.submit': "Send",
        'engage.Contact.thanks': "Thanks!",
        'engage.Contact.error': 'Ooops! There was an error.',

        'engage.privacy': "We respect your privacy and do not share your information with anyone.",
    }
};

export default seeds;
