import { useState } from 'react';
import { observer } from 'mobx-react';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';

import NavSection from '../NavSection';
import { DRAFT_PLAN_LINK, ENGAGEMENT_LINK, ENGAGEMENT_LINK_ES, FINAL_DRADT_PLAN_LINK, PROVIDE_COMMENTS } from '../../config';


export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);

    return (
        <div className="Welcome-wrap">
            <NavSection name="Welcome" index={0}>
                <Anchor onChange={(anchor) => setPromptHidden(anchor.placement === 'offscreen')} />
                <div className='logo'></div>

                <div className="links">
                    {
                    //  <henshu.richtext {...bindTo('engage.calendar.body')} />
                    }

                    { 
                    <div className='links--buttons'>

                            <henshu.a
                                className="button with-arrow"
                                href={FINAL_DRADT_PLAN_LINK}
                                target={'_blank'}
                                {...bindTo('enegage.welcome.button.draftplan')}
                            />
                            {/* <henshu.a
                                className="button with-arrow"
                                href={PROVIDE_COMMENTS}
                                target={'_blank'}
                                {...bindTo('enegage.welcome.button.providecomments')}
                        /> */}


                    </div>
                    
                     }
                </div>

            </NavSection>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 100, left: 0, behavior: 'smooth' })}
            >
                <FiChevronDown />
            </footer>
        </div>
    );
});
