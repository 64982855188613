/**
 * MapSequence
 *
 * This is the map building sequence that remains fixed in the browser window while
 * the user is scrolling. As each Section passes the screens vertical midpoint,
 * the next map image is loaded into the frame.
 */

import { useEffect, useState } from 'react';
import { henshu, useHenshu } from '@strategies/henshu';

import { useContext } from '../../context';


/**
 * Images
 */


import Map1 from './assets/1.jpg';
import Map2 from './assets/2.jpg';
import Map3 from './assets/3.jpg';
import Map4 from './assets/4.jpg';
import Map5 from './assets/5.jpg';
import Map6 from './assets/6.jpg';
import Map7 from './assets/7_Regional Map Community_For Website.jpg';
import Map8 from './assets/8_Regional Map Connectivity_For Website.jpg';

const MAPS = [
    undefined,
    Map1,
    Map2,
    Map3,
    Map4,
    Map5,
    Map6,

];

export default function MapSequence() {
    const { bindTo } = useHenshu();
    const { map } = useContext();
    const [modalActive, setModalActive] = useState<boolean>(false);

    return <>
        <div className="MapSequence">
            <div className={`maps`}>
                {MAPS.map((src, i) => (
                    <div key={`${i}-${src}`} className={`map ${map! === i ? 'active' : ''}`}>
                        <img src={src} alt="" />
                    </div>
                ))}
            </div>
        </div>
    </>;
}
