const firebaseConfig = {
  apiKey: "AIzaSyAR3WbHyuwOj4J1eFZWIuBdZM2cm9OFb2E",
  authDomain: "downtown-littlerock-masterplan.firebaseapp.com",
  projectId: "downtown-littlerock-masterplan",
  storageBucket: "downtown-littlerock-masterplan.appspot.com",
  messagingSenderId: "897279940806",
  appId: "1:897279940806:web:dae6e29dea7bb5f11cdfc7",
  measurementId: "G-VRMKNR7709"
};
export default firebaseConfig;
